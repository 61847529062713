import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './strings/en.json';
import ruTranslation from './strings/ru.json';
import frTranslation from './strings/fr.json';
import esTranslation from './strings/esv2.json';
import ptTranslation from './strings/pt.json';
import zhTranslation from './strings/zh.json';
import jaTranslation from './strings/ja.json';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        detection: {
            order: ['querystring', 'localStorage', 'cookie', 'sessionStorage', 'navigator'],
            lookupQuerystring: 'lang'
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: enTranslation,
            ru: ruTranslation,
            es: esTranslation,
            pt: ptTranslation,
            fr: frTranslation,
            zh: zhTranslation,
            ja: jaTranslation
        }
    });

export default i18n;