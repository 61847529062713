import React, { useRef, useEffect, useState } from "react";
import { NextUIProvider, Container, Row, Col, Image, Button, Spacer, Grid } from "@nextui-org/react";

import { useTranslation } from 'react-i18next';

import "/node_modules/flag-icons/css/flag-icons.min.css";

const languageData = {
  en: {
    flag: 'gb',
    name: 'English'
  },
  ru: {
    flag: 'ru',
    name: 'Русский'
  },
  es: {
    flag: 'es',
    name: 'Español'
  },
  pt: {
    flag: 'pt',
    name: 'Português'
  },
  fr: {
    flag: 'fr',
    name: 'Français'
  },
  zh: {
    flag: 'cn',
    name: '中文'
  },
  ja: {
    flag: 'jp',
    name: '日本語'
  }
};

const supportedLanguages = ['en', 'ru', 'es', 'pt', 'fr', 'zh', 'ja'];

function LanguageIcon({ language, isOpen, onChangeLanguage }) {
  const [isHovering, setIsHovering] = useState(false);

  const langIcon = languageData[language].flag;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        background: isHovering ? '#77777777' : '#00000077',
        paddingLeft: '10px',
        paddingRight: '10px',
        cursor: 'pointer',
        width: '70px',
        minHeight: '70px'
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        if (isOpen) onChangeLanguage(language);
      }}
    >
      <span
        class={"fi fi-" + langIcon}
        style={{
          width: '2rem',
          height: '50px',
        }}
      />
      <p style={{ fontSize: '0.75rem', lineHeight: '0', marginTop: '-0.05rem', textAlign: 'center' }}>{languageData[language].name}</p>
    </div>
  );
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const currLangI18n = i18n.resolvedLanguage ?? 'en';
  const currLang = languageData[currLangI18n] ? currLangI18n : 'en';

  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'fixed',
        height: '50px',
        right: '10px',
        top: '0px',
        bottom: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={() => !isOpen && setIsOpen(true)}
    >
      <LanguageIcon language={currLang} isOpen={isOpen} onChangeLanguage={onChangeLanguage} />

      {isOpen && supportedLanguages.filter(lang => lang != currLang).map(lang => (
        <LanguageIcon key={lang} language={lang} isOpen={isOpen} onChangeLanguage={onChangeLanguage} />
      ))}
    </div>
  );
}

export function Layout({ theme, children }) {
  return (
    <NextUIProvider theme={theme}>
      <div
        style={{
          backgroundImage: "url(https://static.plakmp.com/media/shop/bg1.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          width: "100vw",
          minHeight: "100vh",
          maxWidth: "100%",
        }}>
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 1000,
            padding: "10px",
            width: "100%",
            height: "100%",
            minHeight: "100%",
            maxWidth: "100%",
            background: theme.colors.navbarBg,
          }}
        >
          <Image height="50px" src="/media/navbar_logo.svg" />

          <LanguageSwitcher />
        </div>

        {children}

        <Spacer y={2} />
      </div>
      <footer
        style={{
          width: '100%',
          bottom: 0,
          zIndex: 1000
        }}
      >
        <Container>
          <Col>
            <Spacer y={1} />
            <Grid.Container>
              <Grid xs={12} sm={4} style={{ verticalAlign: 'middle', opacity: '50%' }}>
                <div style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridColumnGap: '15px'
                }}
                >
                  <img height="50px" src="/media/paylogos/paylogo_mastercard_bw.svg" />
                  <img height="50px" src="/media/paylogos/paylogo_visa_bw.svg" />
                  <img height="50px" src="/media/paylogos/paylogo_mir_bw.svg" />
                </div>
              </Grid>
              <Grid xs={12} sm={4} style={{ justifyContent: 'center', textAlign: 'center' }}>
                <div style={{ fontSize: '0.85em' }}>
                  <div style={{ color: '#777' }}>
                    <span>
                      <a style={{ color: '#555', fontWeight: 'bold', textDecoration: 'underline' }} href="https://go.plakmp.com/terms">Terms of Service</a>
                      {" / "}
                      <a style={{ color: '#555', fontWeight: 'bold', textDecoration: 'underline' }} href="https://go.plakmp.com/payterms">Payment Terms</a>
                      {" / "}
                      <a style={{ color: '#555', fontWeight: 'bold', textDecoration: 'underline' }} href="https://go.plakmp.com/privacy">Privacy Policy</a>
                    </span>
                    <Spacer y={0} />
                    <a style={{ color: '#555', fontWeight: 'bold', textDecoration: 'underline' }} href="mailto:support@plakmp.com">support@plakmp.com</a>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={4} style={{ justifyContent: 'right', textAlign: 'right' }}>
                <div style={{ fontSize: '0.85em', color: '#666' }}>
                  <span>IP Ivanov N.I.</span>
                  <Spacer y={0} />
                  <span>Tax ID: 211605274000</span>
                </div>
              </Grid>
            </Grid.Container>
            <Spacer y={0.6} />
          </Col>
        </Container>
      </footer>
    </NextUIProvider>
  );
}

export default Layout;
