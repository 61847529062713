import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { TierShop } from './components/TierShop';

import { createTheme } from "@nextui-org/react";

import './custom.css'

const baseColors = {
  secondaryLight: "#1E1E1E",
  secondaryDark: "#1E1E1E",

  color: "#000000",

  link: "#000000",

  primaryShadow: "#1E1E1E",
    secondaryShadow: "#1E1E1E",
  
  warning: "#FFDB59",

  gradientLight:
    "linear-gradient(146deg, rgba(89, 230, 138,1) -20%, rgba(49,100,56,1) 180%)",
};

const fonts = {
    sans: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
}

const lightTheme = createTheme({
    type: "light",
    theme: {
        colors: {
            ...baseColors,
            primary: "#C1972B",
            primaryLight: "#FFDB59",

            navbarBg: "#0e0e0e",

            gradient:
                "linear-gradient(146deg, rgba(89, 230, 138,1) -20%, rgba(49,100,56,1) 180%)",
        },
        space: {},
        fonts: fonts,
    },
});

const darkTheme = createTheme({
    type: "dark",
    theme: {
        colors: {
            ...baseColors,
            background: "#1E1E1E",
            primary: "#FFDB59",
            primaryLight: "#705B10",

            navbarBg: "#0e0e0e",

            gradient:
                "linear-gradient(146deg, rgba(89, 230, 138, 1) -20%, rgba(49,100,56,1) 180%)",
        },
        space: {},
        fonts: fonts,
    },
});

const theme = darkTheme;

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        var request = new XMLHttpRequest();

        request.open('GET', '/api/content/session', false);  // `false` makes the request synchronous
        request.send(null);

        if (request.status == 200) {
            this.state = {
                session: JSON.parse(request.responseText)
            }; 
        }
        else {
            this.state = {
                session: { pending: false }
            };
        }
    }

    render() {
        return (
          <Layout theme={theme}>
            <Routes>
              <Route
                exact
                path="/"
                element={<TierShop session={this.state.session} />}
              />
              <Route
                path="/purchase/:key"
                element={<TierShop session={this.state.session} />}
              />
            </Routes>
          </Layout>
        );
    }
}
